<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-5">Academic Conduct Agreement</h2>

      <p class="mb-3">
        Click on the following academic conduct statements to indicate you have read and understand
        them.
      </p>

      <p v-for="option in optionsQ1" :key="'pt-1-' + option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.question1Values" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default {
  name: 'UCIrvineIntegrityAgreementQ1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        question1Values: [],
      },
      optionsQ1: [
        {
          text: 'You must follow TA instructions while in lab.',
          value: 'followInstructionsAnswer',
        },
        {
          text: 'You must handle disagreements with labmates respectfully and productively.',
          value: 'disagreementsAnswer',
        },
        {
          text: 'Professional speech and behavior must be used in lab.',
          value: 'behaviorAnswer',
        },
        {
          text: 'Course rules are in place to ensure all students are treated fairly.',
          value: 'rulesAnswer',
        },
        {
          text: 'Grading issues should be respectfully handled in person.',
          value: 'gradingIssuesAnswer',
        },
        {
          text: 'Harassment or bullying of instructors and students, including via electronic media, the internet, social networks, blogs, cell phones, and text messages, will be reported to Student Conduct.',
          value: 'harassmentAnswer',
        },
      ],
    };
  },
};
</script>
